import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';

// MUI:
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {CssBaseline} from '@mui/material';
import {SnackbarProvider} from 'notistack';
import store from './redux/store';
import {ruRU} from '@mui/material/locale'
import {Settings} from "luxon";
import RouteContainer from './components/RouteContainer';

import {BrowserRouter} from "react-router-dom";
import {Notifier} from './components/Notifier';

const themeDefault = createTheme({
    palette: {
        background: {
            default: "#f0f0f0"
        }
    },
    components: {
        MuiCircularProgress: {
            styleOverrides: {
                root: {
                    color: "#2B65F0"
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFFFFF',
                    width: 'fit-content',
                    borderRadius: "10px",
                    padding: "5px 12px",
                    boxShadow: "-3px 3px 16px #0000000F!important"
                }
            }
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: "#2B65F0",
                        color: "#FFFFFF"
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    padding: '10px 18px',
                    fontFamily: 'SF Pro Text, sans-serif',
                    fontSize: "15px",
                    textTransform: "unset!important",
                    letterSpacing: "-0.41px",
                    lineHeight: "unset!important",
                },
                contained: {
                    backgroundColor: '#2B65F0',
                    width: '120px',
                    fontWeight: "500",
                    '&:hover': {
                        backgroundColor: "#3060DC!important"
                    }
                },
                outlined: {
                    color: "#2B65F0",
                    fontWeight: "400",
                },
                text: {
                    color: "#000000",
                    fontWeight: "400",
                    '&:hover': {
                        color: "#000000!important"
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    padding: "8px 12px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    maxWidth: "600px",
                    fontFamily: "SF Pro Text, sans-serif",
                    letterSpacing: "-0.41px",
                    '&:hover fieldset': {
                        borderColor: "#B2BAC2"
                    },
                    '&:focus-visible': {
                        borderColor: "#1365E3!important",
                    },
                    '&:focus-visible &:hover fieldset': {
                        borderColor: "#1365E3!important",
                    }
                },
                input: {
                    padding: "0",
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "#FFFFFF",
                    color: "#000000",
                    boxShadow: "-3px 3px 16px 16px #0000000F",
                    fontSize: "13px",
                    padding: "12px",
                    fontFamily: "SF Pro Text, sans-serif",
                    letterSpacing: "-0.41px",
                    fontWeight: "400",
                    maxWidth: "330px",
                    lineHeight: "18px",
                    borderRadius: "8px"
                },
                arrow: {
                    color: "#FFFFFF",
                }
            },

        },
        MuiAutocomplete: {
            styleOverrides: {
                popper: {
                    inset: "2px auto auto 0px!important"
                },
                paper: {
                    boxShadow: "-3px 3px 16px #0000000F",
                    borderRadius: "10px"
                },
                listbox: {
                    padding: "6px 0 0 0",
                    boxShadow: "-3px 3px 16px #0000000F"
                },
                input: {
                    padding: "0!important"
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontFamily: "SF Pro Text, sans-serif",
                    fontSize: "13px",
                    letterSpacing: "-0.41px",
                    borderRadius: "6px"
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: "6px!important",
                    boxShadow: "-3px 3px 16px #0000000F!important"
                },
                list: {
                    paddingTop: "6px!important",
                    paddingBottom: "6px!important",
                    paddingLeft: "6px!important",
                    paddingRight: "6px!important"
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    padding: "10px!important",
                    fontSize: "14px!important",
                    fontFamily: "SF Pro Text, sans-serif",
                    letterSpacing: "-0.41px",
                    borderRadius: "4px!important"
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                '.Mui-checked+.MuiSwitch-track': {
                    backgroundColor: "#2B65F0"
                },
                root: {
                    padding: "6px",
                    marginLeft: "6px",
                    marginRight: "6px"
                },
                thumb: {
                    backgroundColor: "#FFFFFF!important",
                    color: "#FFFFFF!important"
                },
                track: {
                    borderRadius: "16px",
                    checked: {
                        opacity: "1"
                    }
                },
                switchBase: {
                    '&.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: "#2B65F0",
                        opacity: "1"
                    },
                }
            }
        }
    }
}, ruRU);

//luxon settings
Settings.defaultZone = "Europe/Moscow";

document.documentElement.lang = 'ru';
document.documentElement.translate = false;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    /*<React.StrictMode>*/
    <Provider store={store}>
        <ThemeProvider theme={themeDefault}>
            <CssBaseline/>
            <SnackbarProvider maxSnack={3} autoHideDuration={3000}
                              anchorOrigin={{vertical: "top", horizontal: "right"}}>
                <Notifier/>
                <BrowserRouter>
                    <RouteContainer/>
                </BrowserRouter>
            </SnackbarProvider>
        </ThemeProvider>
    </Provider>
    /*</React.StrictMode>*/
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
