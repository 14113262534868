import React from 'react';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DesktopDatePicker, MobileDatePicker} from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import {ThemeProvider} from "@mui/material/styles";
import {isMobile} from "react-device-detect";
import {ruRU} from "@mui/x-date-pickers/locales";

// Пропсы:
// date: значение выбираемой даты
// onDateChanges: функция для изменения выбираемой даты
// fromDate: соответствующая начальная дата для ограничения выбора конечной даты
// toDate: соответствующая конечная дата для ограничения выбора начальной даты
export const CustomDatepicker = (props) => {
  const [error, setError] = React.useState(null);

  const errorMessage = React.useMemo(() => {
    switch (error) {
      case 'maxDate': {
        return 'Дата позже максимальной';
      }

      case 'minDate': {
        return 'Дата ранее минимальной';
      }

      case 'invalidDate': {
        return 'Некорректная дата';
      }

      default: {
        //&nbsp; чтобы интерфейс не прыгал при появлении ошибок
        return '\u00A0';
      }
    }
  }, [error]);

  return (
    <>
      <LocalizationProvider localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="ru" dateAdapter={AdapterLuxon}>
          {
              isMobile ?
                  <MobileDatePicker
                      sx={{
                          padding: '8px 12px',
                      }}
                      value={props.date}
                      onChange={(value) => {
                          //если дата конечная то добавляем к дате 23 часа 59 минут 59 секунд и 999 мс чтобы поиск был включая конечную дату
                          if (props.fromDate) {
                              if (value) {
                                  const dateWithoutTime = value.set({ hour: 0, minute: 0, second: 0, millisecond: 0});
                                  const modifiedDate = DateTime.fromISO(dateWithoutTime).plus({hours: 23, minutes: 59, seconds: 59, milliseconds: 999});

                                  props.onDateChanges(modifiedDate);
                              }
                              else {
                                  props.onDateChanges(null);
                              }
                          }
                          else {
                              props.onDateChanges(value);
                          }
                      }}
                      onError={(newError) => setError(newError)}
                      minDate={props.fromDate ?? undefined}
                      maxDate={props.toDate ?? undefined}
                      inputFormat="dd.MM.yyyy"
                      mask='__.__.____'
                      renderInput={(params) => {
                          return (
                              <ThemeProvider theme={{
                                  components: {
                                      MuiOutlinedInput: {
                                          styleOverrides: {
                                              root: {
                                                  width: "152px",
                                              },
                                              input: {
                                                  padding: "9px 12px",
                                                  borderRadius: "4px",
                                                  fontSize: "16px",
                                                  fontFamily: "SF Pro Text, serif",
                                                  letterSpacing: "-0.41px",
                                                  '&:hover fieldset': {
                                                      borderColor: "#B2BAC2"
                                                  },
                                                  '&:focus-visible': {
                                                      borderColor: "#1365E3!important",
                                                  },
                                                  '&:focus-visible &:hover fieldset': {
                                                      borderColor: "#1365E3!important",
                                                  },
                                              },
                                          }
                                      }
                                  }}}>
                                  <TextField
                                      {...params}
                                      inputProps={{
                                          ...params.inputProps,
                                          placeholder: ""
                                      }}
                                      helperText={errorMessage}
                                  />
                              </ThemeProvider>

                          );
                      }}
                  /> :
                  <DesktopDatePicker
                      sx={{
                          padding: '8px 12px',
                      }}
                      value={props.date}
                      onChange={(value) => {
                          //если дата конечная то добавляем к дате 23 часа 59 минут 59 секунд и 999 мс чтобы поиск был включая конечную дату
                          if (props.fromDate) {
                              if (value) {
                                  const dateWithoutTime = value.set({ hour: 0, minute: 0, second: 0, millisecond: 0});
                                  const modifiedDate = DateTime.fromISO(dateWithoutTime).plus({hours: 23, minutes: 59, seconds: 59, milliseconds: 999});

                                  props.onDateChanges(modifiedDate);
                              }
                              else {
                                  props.onDateChanges(null);
                              }
                          }
                          else {
                              props.onDateChanges(value);
                          }
                      }}
                      onError={(newError) => setError(newError)}
                      minDate={props.fromDate ?? undefined}
                      maxDate={props.toDate ?? undefined}
                      inputFormat="dd.MM.yyyy"
                      mask='__.__.____'
                      renderInput={(params) => {
                          return (
                              <ThemeProvider theme={{
                                  components: {
                                      MuiOutlinedInput: {
                                          styleOverrides: {
                                              root: {
                                                  width: "152px",
                                              },
                                              input: {
                                                  padding: "8px 12px",
                                                  borderRadius: "4px",
                                                  fontSize: "14px",
                                                  fontFamily: "SF Pro Text, serif",
                                                  letterSpacing: "-0.41px",
                                                  '&:hover fieldset': {
                                                      borderColor: "#B2BAC2"
                                                  },
                                                  '&:focus-visible': {
                                                      borderColor: "#1365E3!important",
                                                  },
                                                  '&:focus-visible &:hover fieldset': {
                                                      borderColor: "#1365E3!important",
                                                  },
                                              },
                                          }
                                      }
                                  }}}>
                                  <TextField
                                      {...params}
                                      inputProps={{
                                          ...params.inputProps,
                                          placeholder: ""
                                      }}
                                      helperText={errorMessage}
                                  />
                              </ThemeProvider>

                          );
                      }}
                  />
          }
      </LocalizationProvider>
    </>
  )
}
