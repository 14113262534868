import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, IconButton, SwipeableDrawer, TextField } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Tree from 'rc-tree';
import '../css/rc-tree.css';
import IndustriesService from '../services/IndustriesService';
import { isMobile } from "react-device-detect";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

export function IndustrySelection(props) {
    const [filterText, setFilterText] = useState('');
    const [filteredData, setFilteredData] = useState(props.IndustryCodes);

    const Puller = styled('div')(({ theme }) => ({
        width: 30,
        height: 6,
        backgroundColor: grey[300],
        borderRadius: 3,
        position: 'absolute',
        top: 8,
        left: 'calc(50% - 15px)'
    }));

    useEffect(() => {
        if (props.openIndustrySelectionModal) {
            props.onInitialLoad();
        }
    }, [props.openIndustrySelectionModal]);

    useEffect(() => {
        if (!filterText) {
            setFilteredData(props.IndustryCodes);
            return;
        }
        const filtered = props.IndustryCodes.filter(item =>
            item.title.toLowerCase().includes(filterText.toLowerCase()) || item.children.some(child => child.title.toLowerCase().includes(filterText.toLowerCase()))
        );
        setFilteredData(filtered);
    }, [filterText, props.IndustryCodes]);

    return (
        <React.Fragment>
            {isMobile ? (
                <SwipeableDrawer
                    anchor="bottom"
                    open={props.openIndustrySelectionModal}
                    onClose={props.onCloseIndustrySelection}
                    PaperProps={{
                        sx: {
                            width: "100%",
                            borderRadius: "16px 16px 0 0",
                            height: "90%",
                        },
                    }}
                    scroll={"paper"}
                >
                    <Puller />
                    <DialogTitle
                        sx={{
                            m: 0,
                            padding: "32px 16px 12px",
                            fontSize: "20px!important",
                            fontWeight: "600",
                            fontFamily: "SF Pro Text, sans-serif",
                            letterSpacing: "-0.41px",
                        }}
                        id="customized-dialog-title"
                    >
                        Выбор отраслей
                    </DialogTitle>

                    <DialogContent sx={{ borderBottom: "0.5px solid #E8E8E8", paddingLeft: "12px", paddingRight: "12px" }}>
                        <TextField
                            fullWidth
                            placeholder="Введите текст для фильтрации"
                            variant="outlined"
                            size="small"
                            sx={{ mb: 2 }}
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                        />
                        {props.isLoading ? (
                            <CircularProgress />
                        ) : (
                            <Tree
                                checkable
                                treeData={filteredData}
                                checkedKeys={props.checkedIndustryCodes.map(code => code.key)}
                                onCheck={props.onSetCheckedIndustryCodes}
                                showIcon={false}
                                className="highlighted"
                            />
                        )}
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'flex-start', pt: 2, pb: 3, paddingLeft: '16px' }}>
                        <Button
                            sx={{
                                borderRadius: '10px',
                                backgroundColor: '#2B65F0',
                                padding: '10px 18px',
                                width: '120px',
                                fontFamily: 'SF Pro Text, serif',
                                fontSize: "16px",
                                textTransform: "unset!important",
                                letterSpacing: "-0.41px",
                                lineHeight: "unset!important",
                                fontWeight: "500",
                                '&:hover': {
                                    backgroundColor: "#3060DC!important"
                                }
                            }}
                            variant="contained"
                            onClick={() => props.onIndustriesSelected(props.checkedIndustryCodes, props.currentPage)}>Выбрать</Button>
                        <Button
                            sx={{
                                borderRadius: '10px',
                                padding: '10px 18px',
                                fontFamily: 'SF Pro Text, serif',
                                fontSize: "16px",
                                textTransform: "unset!important",
                                letterSpacing: "-0.41px",
                                lineHeight: "unset!important",
                                color: "#000000",
                                fontWeight: "400"
                            }}
                            startIcon={<img src="/close_icon.svg" alt="Close" />} variant="text"
                            onClick={props.onCloseIndustrySelection}>Отменить</Button>
                    </DialogActions>
                </SwipeableDrawer>
            ) : (
                <Dialog
                    open={props.openIndustrySelectionModal}
                    onClose={props.onCloseIndustrySelection}
                    PaperProps={{
                        sx: {
                            maxWidth: "750px",
                            width: "750px",
                            borderRadius: "16px",
                            maxHeight: "80%",
                        },
                    }}
                    scroll={"paper"}
                >
                    <DialogTitle
                        sx={{
                            m: 0,
                            p: 3,
                            pb: 0,
                            fontSize: "22px!important",
                            fontWeight: "600",
                            fontFamily: "SF Pro Text, serif",
                            letterSpacing: "-0.41px",
                        }}
                        id="customized-dialog-title"
                    >
                        Выбор отраслей
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={props.onCloseIndustrySelection}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 16,
                            top: 22,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <img src="/close_icon.svg" alt="Close" />
                    </IconButton>
                    <DialogContent sx={{ borderBottom: "0.5px solid #E8E8E8", paddingLeft: "20px", paddingRight: "20px" }}>
                        <TextField
                            fullWidth
                            placeholder="Введите текст для фильтрации"
                            variant="outlined"
                            size="small"
                            sx={{ mb: 2 }}
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                        />
                        {props.isLoading ? (
                            <CircularProgress />
                        ) : (
                            <Tree
                                checkable
                                treeData={filteredData}
                                checkedKeys={props.checkedIndustryCodes.map(code => code.key)}
                                onCheck={props.onSetCheckedIndustryCodes}
                                showIcon={false}
                                className="highlighted"
                            />
                        )}
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'flex-start', pt: 2, pb: 2, paddingLeft: '24px' }}>
                        <Button
                            sx={{
                                borderRadius: '10px',
                                backgroundColor: '#2B65F0',
                                padding: '10px 18px',
                                width: '120px',
                                fontFamily: 'SF Pro Text, serif',
                                fontSize: "16px",
                                textTransform: "unset!important",
                                letterSpacing: "-0.41px",
                                lineHeight: "unset!important",
                                fontWeight: "500",
                                '&:hover': {
                                    backgroundColor: "#3060DC!important"
                                }
                            }}
                            variant="contained"
                            onClick={() => props.onIndustriesSelected(props.checkedIndustryCodes, props.currentPage)}>Выбрать</Button>
                        <Button
                            sx={{
                                borderRadius: '10px',
                                padding: '10px 18px',
                                fontFamily: 'SF Pro Text, serif',
                                fontSize: "16px",
                                textTransform: "unset!important",
                                letterSpacing: "-0.41px",
                                lineHeight: "unset!important",
                                color: "#000000",
                                fontWeight: "400"
                            }}
                            startIcon={<img src="/close_icon.svg" alt="Close" />} variant="text"
                            onClick={props.onCloseIndustrySelection}>Отменить</Button>
                    </DialogActions>
                </Dialog>
            )}
        </React.Fragment>
    );
}

function mapStateToProps(state) {
    return {
        IndustryCodes: state.industriesState.IndustryCodes,
        checkedIndustryCodes: state.industriesState.checkedIndustryCodes,
        openIndustrySelectionModal: state.industriesState.openIndustrySelectionModal,
        isLoading: state.industriesState.isLoading,
        searchWarningMessage: state.industriesState.searchWarningMessage,
        currentPage: state.pagesState.currentPage,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onInitialLoad: () => {
            IndustriesService.getIndustries()
                .then((result) => {
                    const action = {
                        type: 'INDUSTRIES_INITIATED',
                        IndustryCodes: result,
                    };
                    dispatch(action);
                })
                .catch(() => {
                    const notificationAction = {
                        type: 'SNACKBAR_NOTIFICATION_SENT',
                        notification: {
                            message: 'Произошла ошибка при получении списка отраслей',
                            variant: 'error',
                        },
                    };
                    dispatch(notificationAction);
                });
        },
        onSetCheckedIndustryCodes: (checkedIndustryCodes, event) => {
            const action = {
                type: 'INDUSTRY_CODE_CHECKED',
                checkedNode: event.node,
            };
            dispatch(action);
        },
        onIndustriesSelected: (checkedIndustryCodes, currentPage) => {
            const action = {
                type: 'INDUSTRIES_SELECTION_COMPLETED',
                checkedIndustryCodes: checkedIndustryCodes,
                currentPage: currentPage,
            };
            dispatch(action);
        },
        onCloseIndustrySelection: () => {
            const action = {
                type: 'INDUSTRIES_SELECTION_CLOSED',
            };
            dispatch(action);
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IndustrySelection);
