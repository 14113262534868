import React, { useEffect, useState } from 'react'
import styles from '../css/MobileMapMarkedPurchasesPanel.module.css'
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton,
    List,
    ListItemButton,
    SwipeableDrawer,
    Switch
} from "@mui/material";
import { withSnackbar } from "notistack";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "../withRouter";
import { styled, ThemeProvider } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import PurchaseReportService from "../services/PurchaseReportService";
import { UploadSettings } from "../constants/upload-settings";


const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: grey[300],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)'
}));

const MobileMapMarkedPurchasesPanel = (props) => {

    const [showPanel, setShowPanel] = useState(false)
    const [showSortingModal, setShowSortingModal] = useState(false)
    const [showActionsModal, setShowActionsModal] = useState(false)
    const [isLoadingUploadSettings, setIsLoadingUploadSettings] = useState(false)
    const [isOpenUploadSettingsModal, setIsOpenUploadSettingsModal] = useState(false)

    const dispatch = useDispatch();
    const reduxState = useSelector((state) => {
        if (state.pagesState.currentPage === 'PURCHASES') {
            return {
                sortBy: state.purchasesState.sortBy,
                ascending: state.purchasesState.ascending,
                currentPage: state.pagesState.currentPage
            };
        }


        if (state.pagesState.currentPage === 'TEMPLATES') {
            return {
                sortBy: state.templatesState.sortBy,
                ascending: state.templatesState.ascending,
                currentPage: state.pagesState.currentPage
            };
        }
    })


    const options = [
        { title: 'Дата публикации по возрастанию', value: '{"sortBy":\"purchaseCreateAt\","ascending":true}' },
        { title: 'Дата публикации по убыванию', value: '{"sortBy":\"purchaseCreateAt\","ascending":false}' },
        {
            title: 'Дата окончания подачи заявок по возрастанию',
            value: '{"sortBy":\"purchaseExpirationAt\","ascending":true}'
        },
        {
            title: 'Дата окончания подачи заявок по убыванию',
            value: '{"sortBy":\"purchaseExpirationAt\","ascending":false}'
        },
        { title: 'Цена по возрастанию', value: '{"sortBy":\"price\","ascending":true}' },
        { title: 'Цена по убыванию', value: '{"sortBy":\"price\","ascending":false}' }
    ]

    const handleConvertToString = () => {
        PurchaseReportService.convertToString(props.uploadPurchaseIds)
            .then(async (str) => {
                if (!str) {
                    this.props.enqueueSnackbar(
                        'Пустой результат. Необходимо отметить экспортируемые поля',
                        {
                            variant: 'warning',
                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                            autoHideDuration: 4000
                        }
                    );
                    return;
                }

                await navigator.clipboard.writeText(str);

                props.enqueueSnackbar(
                    'Подборки скопированы в буфер',
                    {
                        variant: 'success',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        autoHideDuration: 2000
                    })
            })
            .catch(() => {
                props.enqueueSnackbar(
                    'Произошла ошибка при попытке обработки закупок',
                    {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        autoHideDuration: 4000
                    }
                )
            });
    }

    const handleConvertToExcel = () => {
        PurchaseReportService.convertToExcel(props.uploadPurchaseIds)
            .then(async (res) => {
                if (!res) {
                    this.props.enqueueSnackbar(
                        'Пустой результат. Необходимо отметить экспортируемые поля',
                        {
                            variant: 'warning',
                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                            autoHideDuration: 4000
                        }
                    );
                    return;
                }

                props.enqueueSnackbar(
                    'Загрузка в процессе',
                    {
                        variant: 'success',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        autoHideDuration: 2000
                    })
            })
            .catch(() => {
                props.enqueueSnackbar(
                    'Произошла ошибка при попытке обработки закупок',
                    {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        autoHideDuration: 4000
                    }
                )
            });
    }

    const handleOpenUploadSettingsModal = () => {
        PurchaseReportService.getSettings()
            .then(settings => {
                let filteredSettings = settings.filter(setting => UploadSettings.find(us => us.id == setting));
                props.onUploadSettings(filteredSettings, props.currentPage);
                setIsLoadingUploadSettings(false)
                setIsOpenUploadSettingsModal(true)

            });

        setIsLoadingUploadSettings(true)
        setShowActionsModal(false)
    }

    const handleUploadFieldChange = (event) => {
        let isChecked = event.target.checked;
        let value = event.target.value;

        if (isChecked) {
            props.onAddUploadField(value, props.currentPage);
        } else {
            props.onRemoveUploadField(value, props.currentPage);
        }
    }

    const handleCloseUploadSettingsWithoutSaveModal = () => {
        setIsOpenUploadSettingsModal(false)

    }

    const handleCloseUploadSettingsWithSaveModal = () => {
        setIsOpenUploadSettingsModal(false)

        PurchaseReportService.addOrUpdateSettings(props.uploadFields);
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 2100) {
                setShowPanel(true)
            } else {
                setShowPanel(false)
            }
        });
    }, []);

    return (
        <>
            <div className={showPanel ? styles.container : styles.container + ' ' + styles.displayNone}>
                <div className={styles.searchInfoContainer}>
                    <div className={styles.inputContainer}>
                        <img src="/search_icon_menu.svg" alt="search" className={styles.searchIcon} />
                        <span className="textContainer">
                            {props.keywords.length > 0 && props.keywords[0] !== '' ? props.keywords : 'Нет ключевых слов'}
                        </span>
                    </div>
                    <IconButton aria-label="arrow_up" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                        <img src="/arrow_up.svg" alt="arrow_up" />
                    </IconButton>
                </div>

                <div className={styles.actionsContainer}>
                    <span className={styles.purchasesCount}>
                        {props.total > 0 ? (props.total >= 10000 ? 'Более 10000' : props.total) + " заявок" : null}
                    </span>

                    <div className={styles.actionsInnerContainer}>
                        <Button
                            sx={{
                                padding: '5px 12px!important',
                                borderRadius: "6px"
                            }}
                            variant="outlined"
                            onClick={() => setShowActionsModal(true)}
                        >
                            Доп. действия
                        </Button>

                        <Button
                            variant="outlined"
                            className={styles.iconButton}
                            onClick={() => setShowSortingModal(true)}
                            startIcon={<img src="/sort_icon.svg" alt="sort" />}
                        ></Button>
                    </div>
                </div>
            </div>

            {/*Дополнительные действия*/}
            <SwipeableDrawer
                anchor='bottom'
                open={showActionsModal}
                onClose={() => setShowActionsModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                PaperProps={{
                    sx: {
                        width: "100%",
                        borderRadius: "16px 16px 0 0",
                        maxHeight: "80%",
                        height: "50%"
                    }
                }}
                scroll={"paper"}
            >
                <Puller />
                <DialogTitle
                    sx={{
                        m: 0,
                        padding: '32px 20px 12px',
                        fontSize: "20px!important",
                        fontWeight: "600",
                        fontFamily: "SF Pro Text, sans-serif",
                        letterSpacing: "-0.41px"
                    }}
                    id="customized-dialog-title">
                    Действия
                </DialogTitle>

                <DialogContent sx={{
                    borderBottom: "0.5px solid #E8E8E8",
                    paddingTop: "12px",
                    paddingLeft: "16px",
                    paddingRight: "16px"
                }}>
                    <ThemeProvider
                        theme={{
                            components: {
                                MuiList: {
                                    styleOverrides: {
                                        root: {
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            fontFamily: "SF Pro Text, sans-serif",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            letterSpacing: "-0.41px"
                                        }
                                    }
                                },
                                MuiListItemButton: {
                                    styleOverrides: {
                                        root: {
                                            paddingLeft: "4px",
                                            paddingRight: "4px",
                                            paddingTop: "12px",
                                            paddingBottom: "12px"
                                        }
                                    }
                                }
                            }
                        }}
                    >
                        <List>
                            {
                                props.pagePurchaseIds.every(item => props.uploadPurchaseIds.includes(item))
                                    ?
                                    <ListItemButton
                                        divider
                                        onClick={() => props.onUnmarkMany(props.pagePurchaseIds, props.currentPage)}
                                    >
                                        Убрать все на странице
                                    </ListItemButton>
                                    :
                                    <ListItemButton
                                        divider
                                        onClick={() => props.onMarkMany(props.pagePurchaseIds, props.currentPage)}
                                    >
                                        Отметить все на странице
                                    </ListItemButton>
                            }

                            <ListItemButton
                                divider
                                onClick={() => props.onClearUploadPurchases(props.currentPage)}
                            >
                                Очистить отмеченные ({props.uploadPurchaseIds.length})
                            </ListItemButton>

                            <ListItemButton
                                divider
                                onClick={() => handleConvertToString()}
                            >
                                Копировать в буфер
                            </ListItemButton>

                            <ListItemButton
                                divider
                                onClick={() => handleConvertToExcel()}
                            >
                                Скачать файлом Excel
                            </ListItemButton>

                            <ListItemButton
                                onClick={() => handleOpenUploadSettingsModal()}
                            >
                                Настройки
                            </ListItemButton>
                        </List>
                    </ThemeProvider>
                </DialogContent>

            </SwipeableDrawer>

            {/*Настройки*/}
            <SwipeableDrawer
                anchor='bottom'
                open={isOpenUploadSettingsModal}
                onClose={handleCloseUploadSettingsWithoutSaveModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                PaperProps={{
                    sx: {
                        width: "100%",
                        borderRadius: "16px 16px 0 0",
                        maxHeight: "91%",
                        height: "91%"
                    }
                }}
                scroll={"paper"}
            >
                <Puller />
                <DialogTitle
                    sx={{
                        m: 0,
                        padding: '32px 20px 12px',
                        fontSize: "20px!important",
                        fontWeight: "600",
                        fontFamily: "SF Pro Text, sans-serif",
                        letterSpacing: "-0.41px"
                    }}
                    id="customized-dialog-title">
                    Настройки
                </DialogTitle>
                {
                    isLoadingUploadSettings ?
                        <DialogContent>
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress />
                            </Box>
                        </DialogContent>
                        :
                        <DialogContent sx={{ paddingTop: "0" }}>
                            <Box>
                                <FormGroup sx={{ display: 'flex', flexDirection: 'column', flexWrap: "nowrap" }}>
                                    {
                                        UploadSettings.map((setting, key) => (
                                            <FormControlLabel
                                                className={styles.settingsFormLabel}
                                                control={<Switch />}
                                                label={setting.title}
                                                onChange={handleUploadFieldChange}
                                                value={setting.id}
                                                checked={props.uploadFields.some((field) => field === setting.id)}
                                                key={key}
                                            />
                                        ))
                                    }
                                </FormGroup>
                            </Box>
                        </DialogContent>
                }

                <DialogActions sx={{ justifyContent: 'flex-start', pt: 2, pb: 3, paddingLeft: '16px' }}>
                    <Button
                        sx={{
                            borderRadius: '10px',
                            backgroundColor: '#2B65F0',
                            padding: '10px 18px',
                            width: '120px',
                            fontFamily: 'SF Pro Text, serif',
                            fontSize: "16px",
                            textTransform: "unset!important",
                            letterSpacing: "-0.41px",
                            lineHeight: "unset!important",
                            fontWeight: "500",
                            '&:hover': {
                                backgroundColor: "#3060DC!important"
                            }
                        }}
                        variant="contained"
                        onClick={() => handleCloseUploadSettingsWithSaveModal()}
                    >Сохранить</Button>
                    <Button
                        sx={{
                            borderRadius: '10px',
                            padding: '10px 18px',
                            fontFamily: 'SF Pro Text, serif',
                            fontSize: "16px",
                            textTransform: "unset!important",
                            letterSpacing: "-0.41px",
                            lineHeight: "unset!important",
                            color: "#000000",
                            fontWeight: "400"
                        }}
                        startIcon={<img src="/close_icon.svg" alt="Close" />} variant="text"
                        onClick={() => handleCloseUploadSettingsWithoutSaveModal()}>Отменить</Button>
                </DialogActions>

            </SwipeableDrawer>

            {/*Сортировка*/}
            <SwipeableDrawer
                anchor='bottom'
                open={showSortingModal}
                onClose={() => setShowSortingModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                PaperProps={{
                    sx: {
                        width: "100%",
                        borderRadius: "16px 16px 0 0",
                        maxHeight: "80%",
                        height: "63%"
                    }
                }}
                scroll={"paper"}
            >
                <Puller />
                <DialogTitle
                    sx={{
                        m: 0,
                        padding: '32px 20px 12px',
                        fontSize: "20px!important",
                        fontWeight: "600",
                        fontFamily: "SF Pro Text, sans-serif",
                        letterSpacing: "-0.41px"
                    }}
                    id="customized-dialog-title">
                    Сортировка
                </DialogTitle>

                <DialogContent sx={{
                    borderBottom: "0.5px solid #E8E8E8",
                    paddingTop: "12px",
                    paddingLeft: "16px",
                    paddingRight: "16px"
                }}>
                    <ThemeProvider
                        theme={{
                            components: {
                                MuiList: {
                                    styleOverrides: {
                                        root: {
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            fontFamily: "SF Pro Text, sans-serif",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            letterSpacing: "-0.41px"
                                        }
                                    }
                                },
                                MuiListItemButton: {
                                    styleOverrides: {
                                        root: {
                                            paddingLeft: "4px",
                                            paddingRight: "4px",
                                            paddingTop: "12px",
                                            paddingBottom: "12px"
                                        }
                                    }
                                }
                            }
                        }}
                    >
                        <List>
                            {
                                options.map((option, key) => (
                                    <ListItemButton divider={key < options.length - 1}
                                        key={option.value}
                                        disabled={option.value === JSON.stringify({
                                            sortBy: reduxState.sortBy,
                                            ascending: reduxState.ascending
                                        })}
                                        onClick={() => {
                                            const action = {
                                                type: 'SORT_BY_CHANGED',
                                                value: option.value,
                                                currentPage: reduxState.currentPage
                                            }
                                            dispatch(action);
                                            setShowActionsModal(false)
                                        }}
                                    >
                                        {option.title}
                                    </ListItemButton>
                                ))
                            }
                        </List>
                    </ThemeProvider>
                </DialogContent>

            </SwipeableDrawer>
        </>
    )
}

function mapStateToProps(state) {
    if (state.pagesState.currentPage === "PURCHASES") {
        return {
            uploadFields: state.markedPurchasesState.uploadFields,
            uploadPurchaseIds: state.markedPurchasesState.uploadPurchaseIds,
            total: state.purchasesState.total,
            currentPage: state.pagesState.currentPage,
            pagePurchaseIds: state.purchasesState.purchases.map((purchase) => purchase.id)
        };
    }
    if (state.pagesState.currentPage === "TEMPLATES") {
        return {
            uploadFields: state.markedTemplatesState.uploadFields,
            uploadPurchaseIds: state.markedTemplatesState.uploadPurchaseIds,
            total: state.templatesState.total,
            currentPage: state.pagesState.currentPage,
            pagePurchaseIds: state.templatesState.purchases.map((purchase) => purchase.id)
        };
    }
    return {
        purchases: [],
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onAddUploadField: (uploadField, currentPage) => {
            const action = {
                type: 'ADD_UPLOAD_FIELD',
                uploadField: uploadField,
                currentPage: currentPage
            }
            dispatch(action);
        },
        onRemoveUploadField: (uploadField, currentPage) => {
            const action = {
                type: 'REMOVE_UPLOAD_FIELD',
                uploadField: uploadField,
                currentPage: currentPage
            }
            dispatch(action);
        },
        onClearUploadPurchases: (currentPage) => {
            const action = {
                type: 'CLEAR_UPLOAD_PURCHASES',
                currentPage: currentPage
            }
            dispatch(action);
        },
        onUploadSettings: (settings = [], currentPage) => {
            const action = {
                type: 'UPLOAD_SETTINGS',
                settings: settings,
                currentPage: currentPage
            };
            dispatch(action);
        },
        onMarkMany: (uploadPurchaseIds, currentPage) => {
            const action = {
                type: 'ADD_MANY_UPLOAD_FIELDS',
                uploadPurchaseIds: uploadPurchaseIds,
                currentPage: currentPage
            };
            dispatch(action);
        },
        onUnmarkMany: (uploadPurchaseIds, currentPage) => {
            const action = {
                type: 'REMOVE_MANY_UPLOAD_FIELDS',
                uploadPurchaseIds: uploadPurchaseIds,
                currentPage: currentPage
            };
            dispatch(action);
        }
    }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withRouter(MobileMapMarkedPurchasesPanel)));
