import React, { Component } from 'react';
import PurchaseReportService from '../services/PurchaseReportService';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack,
    Menu,
    MenuItem,
    Typography
} from '@mui/material';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { withRouter } from '../withRouter';
import { UploadSettings } from '../constants/upload-settings';
import { PurchaseSearchResultSortingPanel } from "./PurchaseSearchResultSortingPanel";
import DialogContent from "@mui/material/DialogContent";
import BpCheckbox from "./CustomCheckbox";


class MarkedPurchasesPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenUploadSettingsModal: false,
            isLoadingUploadSettings: false,
            anchorEl: null,
            isOpenMenu: false
        };

        this.handleOpenUploadSettingsModal = this.handleOpenUploadSettingsModal.bind(this);
        this.handleConvertToString = this.handleConvertToString.bind(this);
        this.handleConvertToExcel = this.handleConvertToExcel.bind(this);
        this.handleCloseUploadSettingsWithoutSaveModal = this.handleCloseUploadSettingsWithoutSaveModal.bind(this);
        this.handleUploadFieldChange = this.handleUploadFieldChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }


    handleUploadFieldChange(event) {
        var isChecked = event.target.checked;
        var value = event.target.value;

        if (isChecked) {
            this.props.onAddUploadField(value, this.props.currentPage);
        } else {
            this.props.onRemoveUploadField(value, this.props.currentPage);
        }
    }


    handleClick(event) {
        if (!this.state.isOpenMenu) {
            this.setState({
                anchorEl: event.currentTarget,
                isOpenMenu: true
            });
        } else {
            this.handleClose()
        }
    }

    handleClose() {
        this.setState({
            anchorEl: null,
            isOpenMenu: false
        });
    }

    handleOpenUploadSettingsModal() {
        PurchaseReportService.getSettings()
            .then(settings => {
                //remove non-actual settings
                var filteredSettings = settings.filter(setting => UploadSettings.find(us => us.id == setting));
                this.props.onUploadSettings(filteredSettings, this.props.currentPage);
                this.setState({
                    isLoadingUploadSettings: false,
                    isOpenUploadSettingsModal: true
                });
            });

        this.setState({
            isLoadingUploadSettings: true
        })
    }

    handleConvertToString() {
        PurchaseReportService.convertToString(this.props.uploadPurchaseIds)
            .then(async (str) => {
                if (!str) {
                    this.props.enqueueSnackbar(
                        'Пустой результат. Необходимо отметить экспортируемые поля',
                        {
                            variant: 'warning',
                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                            autoHideDuration: 4000
                        }
                    );
                    return;
                }

                await navigator.clipboard.writeText(str);

                this.props.enqueueSnackbar(
                    'Подборки скопированы в буфер',
                    {
                        variant: 'success',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        autoHideDuration: 2000
                    })
            })
            .catch(() => {
                this.props.enqueueSnackbar(
                    'Произошла ошибка при попытке обработки закупок',
                    {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        autoHideDuration: 4000
                    }
                )
            });
    }

    handleConvertToExcel() {
        PurchaseReportService.convertToExcel(this.props.uploadPurchaseIds)
            .then(async (res) => {
                if (!res) {
                    this.props.enqueueSnackbar(
                        'Пустой результат. Необходимо отметить экспортируемые поля',
                        {
                            variant: 'warning',
                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                            autoHideDuration: 4000
                        }
                    );
                    return;
                }

                this.props.enqueueSnackbar(
                    'Загрузка в процессе',
                    {
                        variant: 'success',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        autoHideDuration: 2000
                    })
            })
            .catch(() => {
                this.props.enqueueSnackbar(
                    'Произошла ошибка при попытке обработки закупок',
                    {
                        variant: 'error',
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        autoHideDuration: 4000
                    }
                )
            });
    }

    handleCloseUploadSettingsWithoutSaveModal() {
        this.setState({
            isOpenUploadSettingsModal: false
        })
    }

    handleCloseUploadSettingsWithSaveModal() {
        this.setState({
            isOpenUploadSettingsModal: false
        })

        PurchaseReportService.addOrUpdateSettings(this.props.uploadFields);
    }

    render() {
        if (this.props.total > 0) {
            return (
                <React.Fragment>
                    <div style={{
                        width: '100%',
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                        borderRadius: "20px",
                        boxShadow: "-3px 3px 16px #0000000F"
                    }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: "space-between",
                                p: 3,
                                bgcolor: 'background.paper',
                                borderRadius: 5,
                                border: "none",
                                borderColor: "lightgray"
                            }}
                        >
                            <div>
                                <PurchaseSearchResultSortingPanel onChange={this.props.onSortingChange} />
                                <Box sx={{ width: '100%', fontSize: "15px" }}>
                                    {this.props.total > 0 ? (this.props.total >= 10000 ? 'Более 10000' : this.props.total) + " заявок" : null}
                                    {this.props.total > 10000 ?
                                        <Typography variant="caption" component="div">(отображаются только первые 10000
                                            записей)</Typography> : null}
                                </Box>
                            </div>

                            <Box sx={{ flexShrink: 0 }}>
                                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                    {
                                        this.props.pagePurchaseIds.every(item => this.props.uploadPurchaseIds.includes(item)) ? (
                                            <Button
                                                sx={{
                                                    width: "unset!important",
                                                    padding: '8px 16px!important',
                                                    maxHeight: "36.63px!important",
                                                }}
                                                variant="contained"
                                                onClick={() => this.props.onUnmarkMany(this.props.pagePurchaseIds, this.props.currentPage)}
                                            >
                                                Убрать все на странице
                                            </Button>
                                        ) : (
                                            <Button
                                                sx={{
                                                    padding: '8px 16px!important',
                                                    maxHeight: "36.63px!important",
                                                }}
                                                variant="outlined"
                                                onClick={() => this.props.onMarkMany(this.props.pagePurchaseIds, this.props.currentPage)}
                                            >
                                                Отметить все на странице
                                            </Button>
                                        )
                                    }
                                    <Button
                                        sx={{
                                            padding: '8px 16px!important',
                                            maxHeight: "36.63px!important",
                                        }}
                                        variant="outlined"
                                        onClick={() => this.props.onClearUploadPurchases(this.props.currentPage)}
                                    >
                                        Очистить отмеченные ({this.props.uploadPurchaseIds.length})
                                    </Button>
                                    <Box>
                                        <Button
                                            sx={{
                                                padding: '8px 16px!important',
                                                maxHeight: "36.63px!important",
                                            }}
                                            variant="outlined"
                                            onClick={(e) => this.setState({ exportMenuAnchor: e.currentTarget })}
                                        >
                                            Экспорт отмеченных
                                        </Button>
                                        <Menu
                                            anchorEl={this.state.exportMenuAnchor}
                                            open={Boolean(this.state.exportMenuAnchor)}
                                            onClose={() => this.setState({ exportMenuAnchor: null })}
                                        >
                                            <MenuItem onClick={() => {
                                                this.setState({ exportMenuAnchor: null });
                                                this.handleConvertToString();
                                            }}>
                                                В буфер
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                this.setState({ exportMenuAnchor: null });
                                                this.handleConvertToExcel();
                                            }}>
                                                Файлом excel
                                            </MenuItem>
                                        </Menu>
                                    </Box>
                                    <Button
                                        sx={{
                                            padding: '8px 16px!important',
                                            maxHeight: "36.63px!important",
                                        }}
                                        variant="outlined"
                                        onClick={() => this.handleOpenUploadSettingsModal()}
                                    >
                                        Настройки
                                    </Button>
                                </Stack>
                            </Box>
                        </Box>
                    </div>

                    <Dialog
                        open={this.state.isOpenUploadSettingsModal}
                        onClose={this.handleOpenUploadSettingsModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        PaperProps={{
                            sx: {
                                maxWidth: "400px",
                                width: "400px",
                                borderRadius: "16px",
                                maxHeight: "80%"
                            }
                        }}
                        scroll={"paper"}
                    >
                        <DialogTitle sx={{
                            m: 0,
                            p: 3,
                            fontSize: "22px!important",
                            fontWeight: "600",
                            fontFamily: "SF Pro Text, serif",
                            letterSpacing: "-0.41px"
                        }} id="customized-dialog-title">
                            Настройки
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={() => this.handleCloseUploadSettingsWithoutSaveModal()}
                            sx={(theme) => ({
                                position: 'absolute',
                                right: 16,
                                top: 22,
                                color: theme.palette.grey[500],
                            })}
                        >
                            <img src="/close_icon.svg" alt="Close" />
                        </IconButton>
                        {
                            this.state.isLoadingUploadSettings ?
                                <DialogContent>
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress />
                                    </Box>
                                </DialogContent>
                                :
                                <DialogContent sx={{ paddingTop: "0" }}>
                                    <Box>
                                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                                            {
                                                UploadSettings.map((setting, key) => (
                                                    <FormControlLabel
                                                        control={<BpCheckbox />}
                                                        label={setting.title}
                                                        onChange={this.handleUploadFieldChange}
                                                        value={setting.id}
                                                        checked={this.props.uploadFields.some((field) => field === setting.id)}
                                                        key={key}
                                                    />
                                                ))
                                            }
                                        </FormGroup>
                                    </Box>
                                </DialogContent>
                        }

                        <DialogActions sx={{ justifyContent: 'flex-start', pt: 2, pb: 2, paddingLeft: '24px' }}>
                            <Button
                                variant="contained"
                                onClick={() => this.handleCloseUploadSettingsWithSaveModal()}
                            >Сохранить</Button>
                            <Button
                                startIcon={<img src="/close_icon.svg" alt="Close" />} variant="text"
                                onClick={() => this.handleCloseUploadSettingsWithoutSaveModal()}>Отменить</Button>
                        </DialogActions>

                    </Dialog>
                </React.Fragment>
            );
        }
    }
}

function mapStateToProps(state) {
    if (state.pagesState.currentPage === "PURCHASES") {
        return {
            uploadFields: state.markedPurchasesState.uploadFields,
            uploadPurchaseIds: state.markedPurchasesState.uploadPurchaseIds,
            total: state.purchasesState.total,
            currentPage: state.pagesState.currentPage,
            pagePurchaseIds: state.purchasesState.purchases.map((purchase) => purchase.id)
        };
    }
    if (state.pagesState.currentPage === "TEMPLATES") {
        return {
            uploadFields: state.markedTemplatesState.uploadFields,
            uploadPurchaseIds: state.markedTemplatesState.uploadPurchaseIds,
            total: state.templatesState.total,
            currentPage: state.pagesState.currentPage,
            pagePurchaseIds: state.templatesState.purchases.map((purchase) => purchase.id)
        };
    }
    return {
        purchases: [],
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onAddUploadField: (uploadField, currentPage) => {
            const action = {
                type: 'ADD_UPLOAD_FIELD',
                uploadField: uploadField,
                currentPage: currentPage
            }
            dispatch(action);
        },
        onRemoveUploadField: (uploadField, currentPage) => {
            const action = {
                type: 'REMOVE_UPLOAD_FIELD',
                uploadField: uploadField,
                currentPage: currentPage
            }
            dispatch(action);
        },
        onClearUploadPurchases: (currentPage) => {
            const action = {
                type: 'CLEAR_UPLOAD_PURCHASES',
                currentPage: currentPage
            }
            dispatch(action);
        },
        onUploadSettings: (settings = [], currentPage) => {
            const action = {
                type: 'UPLOAD_SETTINGS',
                settings: settings,
                currentPage: currentPage
            };
            dispatch(action);
        },
        onMarkMany: (uploadPurchaseIds, currentPage) => {
            const action = {
                type: 'ADD_MANY_UPLOAD_FIELDS',
                uploadPurchaseIds: uploadPurchaseIds,
                currentPage: currentPage
            };
            dispatch(action);
        },
        onUnmarkMany: (uploadPurchaseIds, currentPage) => {
            const action = {
                type: 'REMOVE_MANY_UPLOAD_FIELDS',
                uploadPurchaseIds: uploadPurchaseIds,
                currentPage: currentPage
            };
            dispatch(action);
        }
    }
}

export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(withRouter(MarkedPurchasesPanel)));
